.radioButton__real {
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.radioButton__container {
  --radio-button-size: 18px;
  --center-size: 5px;
  --colors: inset 0px 0px 0px 5px #e0e4e8;

  width: calc(18px + 1px);
  height: calc(18px + 1px);
  border-radius: 50px;
  border: 1px solid transparent;
  box-shadow: inset 0px 0px 0px 5px #e0e4e8;
  transition: ease-in box-shadow 0.1s;
}

.radioButton__container:hover:not(.radioButton__containerChecked),
.radioButton__container:focus:not(.radioButton__containerChecked) {
  box-shadow: inset 0px 0px 0px 5px #a7aebb;
}

.radioButton__containerChecked {
  box-shadow: inset 0px 0px 0px 5px hsl(279.8, 45.1%, 52.2%);
}

.radioButton__containerActive {
  box-shadow: inset 0px 0px 0px 5px hsla(279.8, 45.1%, 52.2%, 0.75);
}
