.checkmark {
  min-width: 18px;
  min-height: 18px;
  border-radius: 4px;
  margin-right: 0.4em;
}

.checkmark.checkmark-blue {
  background-color: #2facbf;
}

.checkmark.checkmark-purple {
  background-color: #974ebc;
}

.checkmark.blank {
  border: 2px solid #c4cdd5;
}

.checkmark.selected {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark.selected span.check {
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  border-radius: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
