.row {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  padding: 0 10px 0 10px;
}

.row > *:not(.modal) {
  margin-top: calc(4px * 4);
}

.row > .modal__main,
.row > .modal {
  margin-top: 0;
}

.row.row.row--2-1 > *,
.row.row--fluid > * {
  margin-right: unset;
  margin-left: unset;
}

.row.row--2-1 > *:first-child {
  width: 100%;
}

.row.row--2-1 > *:nth-child(2) {
  width: 100%;
}

.row.row--fluid > * {
  width: 100%;
}

.row.row--body-chart-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(100% / 1.1));
  grid-gap: 1rem;
  justify-content: space-between;
}

@media screen and (min-width: 680px) {
  .row.row.row--body-chart-row {
    grid-template-columns: repeat(auto-fill, calc(100% / 2.1));
  }
}

@media screen and (min-width: 1120px) {
  .row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }

  .row.row.row--2-1 > *:first-child,
  .row.row--fluid > *:first-child {
    margin-right: calc(4px * 4);
  }

  .row.row--2-1 > *:first-child {
    width: 60%;
  }

  .row.row--2-1 > *:nth-child(2) {
    width: 40%;
  }

  .row.row--fluid > * {
    width: 50%;
  }
}

@media screen and (min-width: 1260px) {
  .row.row.row--body-chart-row {
    grid-template-columns: repeat(auto-fill, calc(100% / 3.1));
  }
}
