/**
 * THIS CSS IS GLOBAL, BE CAREFUL WHEN EDITING THIS
 */
:root {
  --white: #fff;
  --empty-background: #f2f2f2;

  /* Primary Color Base */
  --fc-primary-hue: 279.8;
  --fc-primary-saturation: 45.1%;
  --fc-primary-lightness: 52.2%;

  /* Secondary Color Base */
  --fc-secondary-hue: 280;
  --fc-secondary-saturation: 41.6%;
  --fc-secondary-lightness: 26.9%;

  /* Teal Color Base */
  --fc-teal-hue: 187.9;
  --fc-teal-saturation: 60.5%;
  --fc-teal-lightness: 46.7%;

  /* Full color */
  --fc-primary: hsl(279.8, 45.1%, 52.2%);
  --fc-secondary: hsl(280, 41.6%, 26.9%);
  --fc-teal: hsl(187.9, 60.5%, 46.7%);
  --fc-yellow: #ff9200;
  --fc-red: #cd5c5c;
  /* Hover */
  --hover-primary: hsl(279.8, 45.1%, 62.2%);
  --hover-secondary: hsl(280, 41.6%, calc(26.9% + 10%));
  --hover-teal: hsl(187.9, 60.5%, 56.7%);

  /* Active */
  --active-primary: hsl(279.8, 45.1%, 57.2%);
  --active-secondary: hsl(280, 41.6%, calc(26.9% + 5%));
  --active-teal: hsl(
    var(--fc-teal-hue),
    var(--fc-teal-saturation),
    calc(var(--fc-teal-lightness) + 5%)
  );

  /* Disabled */
  --disabled-primary: hsla(279.8, 45.1%, 52.2%, 0.75);
  --disabled-teal: hsla(187.9, 60.5%, 46.7%, 0.75);

  /* Font */
  --font-darkness: #0a1f44;
  --font-dark: #53627c;
  --font-medium: #8a94a6;
  --font-light: #b5bbc6;

  /* Icon */
  --icon-color: var(--font-medium);

  /* Input */
  --input-hover: #a7aebb;
  --input-active: var(--fc-primary);
  --input-disabled: #f7f8f9;
  --input-default: #e0e4e8;
  --input-error: #cd5c5c;
  --input-pressed: var(--disabled-primary);

  /* Service Color */
  --service-success: var(--fc-teal);
  --service-alert: var(--fc-yellow);
  --service-error: #cd5c5c;

  /* Shadow */
  --shadow-1: 0px 0px 4px rgba(53, 66, 91, 0.08);
  --shadow-2: 0px 0px 8px rgba(53, 66, 91, 0.1);
  --shadow-3: 0px 0px 12px rgba(0, 0, 0, 0.1);
  --shadow-4: 0px 0px 16px rgba(53, 66, 91, 0.12);
  --shadow-5: 0px 0px 24px rgba(53, 66, 91, 0.12);

  /* Gray */
  --gray-500: #c9ced6;
  --athens-gray: #f1f2f4;
  --gray-chateau: #a0a4a8;
  --abbey: #52575c;
}
