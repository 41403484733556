.change-in-pain-level {
  min-height: 462px;
  height: fit-content;
}

.change-in-pain-level .change-in-pain-level__title {
  margin-bottom: calc(4px * 6);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.change-in-pain-level .change-in-pain-level__placeholder {
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.change-in-pain-level .change-in-pain-level__chart-container {
  position: relative;
}

.change-in-pain-level .change-in-pain-level__y-label {
  position: absolute;
  left: -65px;
  top: 35%;
  transform: translateY(50%) rotate(270deg);
}

.change-in-pain-level .change-in-pain-level__chart {
  margin-bottom: 12px;
  padding-left: 12px;
}

.change-in-pain-level .change-in-pain-level__x-label {
  display: block;
  text-align: center;

  position: relative;
  bottom: 20px;
}

/*********************************************************/
/* Custom Select Styles */

.change-in-pain-level .select {
  font-family: CircularStd, Arial, Helvetica, sans-serif;
  color: hsl(187.9, 60.5%, 46.7%);
  height: 24px;
  z-index: auto;
  width: 112px;
}

.change-in-pain-level .select__control {
  min-height: 24px;
}

.change-in-pain-level .select .select__menu {
  z-index: 70;
}

.change-in-pain-level .select__value-container {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.change-in-pain-level .select__indicator {
  padding: 0;
}

.change-in-pain-level .select__indicator path {
  fill: hsl(187.9, 60.5%, 46.7%);
}

.change-in-pain-level .select__single-value {
  font-family: CircularStd, Arial, Helvetica, sans-serif;
  color: hsl(187.9, 60.5%, 46.7%);
  font-size: 16px;
  line-height: 24px;
}

.change-in-pain-level .select .select__control {
  cursor: pointer;
  height: inherit;
  border: none;
  border-radius: 8px;
}

.change-in-pain-level .select .select__control.select__control--is-focused,
.change-in-pain-level .select .select__control.select__control--menu-is-open {
  border: none;
  box-shadow: none;
}

.change-in-pain-level .select .select__option {
  height: 48px;
  display: flex;
  align-items: center;
}

.change-in-pain-level .select .select__option:hover,
.change-in-pain-level .select .select__option--is-focused {
  cursor: pointer;
  color: #ffffff;
  background-color: hsla(187.9, 60.5%, 46.7%, 0.75);
}

.change-in-pain-level .select .select__option--is-selected {
  background-color: hsl(187.9, 60.5%, 46.7%);
  color: #ffffff;
}

.change-in-pain-level .select .select__indicator-separator {
  display: none;
}
