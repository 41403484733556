.open-tasks {
  width: 100%;
  max-height: 590px;
}

.open-tasks__table-container {
  display: none;
}

.open-tasks .open-tasks__table {
  display: grid;
  margin-top: 15px;
  overflow-x: auto;
  grid-template-areas:
    'header'
    'body';
}

.open-tasks__accordion {
  display: block;
}

.open-tasks__accordion .accordion__items {
  overflow-y: scroll;
  max-height: 280px;
}

.open_task__acordion-sign-in-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.open-task__patient-name-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
}

@media screen and (min-width: 768px) {
  .open-tasks__table-container {
    display: block;
  }
  .open-tasks__accordion {
    display: none;
  }

  .open-tasks__table {
    display: block;
  }

  .container-resume {
    max-height: 410px;
  }

  .container-expanded {
    max-height: 540px;
  }
}

.open-tasks > section {
  height: 100%;
}

.open-tasks__pdf-viewer {
  border: 1px solid #52575c;
  width: 100%;
  height: 100%;
}

.open-tasks .open-tasks__show-more-container,
.open-tasks__accordion .open-tasks__show-more-container {
  display: flex;
  justify-content: center;
  border-top: 1px solid #e8e8e8;
  padding: calc(4px * 5) 0;
}

.open-tasks .open-tasks__show-more-container .open-tasks__show-more-button,
.open-tasks__accordion .open-tasks__show-more-button {
  display: flex;
  flex-direction: row;
  width: fit-content;
  cursor: pointer;
  background-color: inherit;
  border: none;
}

.open-tasks .open-tasks__show-more-icon,
.open-tasks__accordion .open-tasks__show-more-icon {
  margin: 0px 10px 0px 0px;
  fill: #52575c;
}

.open-tasks > .open-tasks__error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.open-tasks .open-tasks__placeholder {
  height: 100%;
}

.open-tasks .open-tasks__title {
  margin-bottom: 4px;
}

.open-tasks .open-tasks__error-message {
  margin-bottom: calc(4px * 3);
}

.open-tasks .open-tasks__table-header {
  height: 72px;
}

.open-tasks .open-tasks__table-row {
  height: 56px;
}

.open-tasks .open-tasks__table-header,
.open-tasks .open-tasks__table-row {
  display: grid;
  grid-template-columns: repeat(4, 2fr) 1fr;
  align-items: center;
}

.open-tasks .open-tasks__table-header > *:not(:last-child),
.open-tasks .open-tasks__table-row > *:not(:last-child) {
  text-align: left;
}

.open-tasks .open-tasks__table-header > *:last-child,
.open-tasks .open-tasks__table-row > *:last-child {
  text-align: center;
}

.open-tasks .open-tasks__table-body {
  max-height: 300px;
  min-width: 900px;
  overflow-y: auto;
  list-style: none;
}

.open-tasks .open-tasks__table-header,
.open-tasks .open-tasks__table-row {
  border-bottom: 1px solid #e8e8e8;
  padding: 0 calc(4px * 7);
}

.open-tasks .open-tasks__table-row:last-child {
  border: none;
}

.open-tasks .open-tasks__button {
  margin: auto;
  width: calc(4px * 22);
}

.open-tasks__sign-option-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  margin: 0px 0px 20px 0px;
  align-items: center;
}

.open-tasks__sign-footer {
  display: flex;
  justify-content: flex-end;
}

.open-tasks__sign-footer > button {
  margin: 10px;
}

.open-tasks__sign-note {
  height: 150px;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  resize: none;
}

.open-tasks__sign-note-valid {
  border: 1px solid #52575c;
}

.open-tasks__sign-note-invalid {
  border: 2px solid #cd5c5c;
}

.open-tasks__sign-status-container {
  display: flex;
  align-items: center;
}

.signStatus__icon__container {
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin: 8px;
}

.signStatus__icon__container__success {
  border: 6px solid #d5eef2;
}

.signStatus__icon__container__failure {
  border: 6px solid #fde7e7;
}

.success_icon {
  fill: hsl(187.9, 60.5%, 46.7%);
}

.failure_icon {
  fill: #cd5c5c;
}

.open-tasks__modal-content-container {
  padding: 12px 24px;
  margin-top: -10px;
  height: calc(100% - 70px - 50px);
}

.open-tasks__accordion .accordion__item-header {
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .open-tasks__modal-content-container {
    height: calc(100% - 70px - 20px);
  }
}
