.accordion__header {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 36px 0.8rem 12px;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}

.accordion__header.extra-space {
  padding-left: 36px;
}

.accordion__items {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  position: relative;
}

.accordion__item {
  display: flex;
  flex-direction: column;
}

.accordion__item-header {
  height: 52px;
  font-size: 14px;
  line-height: 20px;
  color: #454f5b;
}
.accordion__item-header .arrow-icon {
  fill: #919eab;
}

.accordion__item-body {
  display: flex;
  flex-direction: column;
}

.accordion__item-body-row {
  padding: 12px 32px 12px 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3e8;
  align-items: center;
}

.accordion__item-body-row:last-child {
  border-bottom: 1px solid #919eab;
}

@media screen and (min-width: 768px) {
  .accordion__item-header {
    cursor: pointer;
  }
}
