.knee {
  min-height: 314px;
  height: fit-content;
}

.knee .knee__title[data-state='chart'] {
  margin-bottom: calc(4px * 6);
}

.knee .knee__title[data-state='table'] {
  margin-bottom: calc(4px * 2);
}

.knee .knee__message {
  margin-bottom: calc(4px * 6);
}

.knee .knee__placeholder {
  height: 254px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.knee .knee__empty-placeholder {
  height: 198px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.knee .knee__chart-container {
  position: relative;
}

.knee .knee__chart {
  padding-left: 20px;
}

@media screen and (min-width: 1440px) {
  .knee .knee__chart-container {
    max-width: 100%;
  }
}

.knee .knee__view-toggler {
  font-family: CircularStd, Arial, Helvetica, sans-serif;
  color: hsl(187.9, 60.5%, 46.7%);
  height: 24px;
  z-index: auto;
  width: 112px;
  position: absolute;
  top: calc(4px * 8);
  right: calc(4px * 7);
  width: auto;
  height: auto;
  padding: 0;
}

.knee__label--x {
  display: block;
  text-align: center;
}

.knee .knee__label--y {
  position: absolute;
  left: -65px;
  top: 35%;
  transform: translateY(50%) rotate(270deg);
}

.knee .knee__detail-table hr {
  margin: 0;
}

.knee .knee__detail-table .knee__detail-table-header,
.knee .knee__detail-table .knee__detail-table-row {
  padding: calc(4px * 4);
}

.knee .knee__detail-table .knee__detail-table-header ul,
.knee .knee__detail-table-row ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.knee .knee__detail-table .knee__detail-table-header ul li:first-child,
.knee .knee__detail-table .knee__detail-table-row ul li:first-child {
  text-align: left;
}

.knee .knee__detail-table .knee__detail-table-header ul li:not(:first-child),
.knee .knee__detail-table .knee__detail-table-row ul li:not(:first-child) {
  text-align: center;
}

.knee .knee__detail-table .knee__detail-table-header ul li:last-child,
.knee .knee__detail-table .knee__detail-table-row ul li:last-child {
  text-align: right;
  padding-right: calc(4px * 5);
}
