.age-distribution {
  min-height: 352px;
  height: fit-content;
}

.age-distribution .age-distribution__title {
  margin-bottom: calc(4px * 5);
}

.age-distribution .age-distribution__placeholder {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.age-distribution .age-distribution__chart-container {
  position: relative;
  margin-bottom: calc(4px * 4);
}

.age-distribution__legends {
  display: flex;
  justify-content: center;
  max-width: 309px;
  margin: 0 auto calc(4px * 6) auto;
}

.age-distribution__legends > *:first-child {
  margin-right: calc(4px * 8);
}

.age-distribution__legend {
  display: flex;
  align-items: center;
}

.age-distribution__legend-color {
  border-radius: 2px;
  width: calc(4px * 4);
  height: calc(4px * 4);
  margin-right: calc(4px * 3);
}
