.button {
  --button-background-color: hsl(279.8, 45.1%, 52.2%);
  --button-color: var(--white);
  --button-border: none;

  color: #ffffff;
  background-color: hsl(279.8, 45.1%, 52.2%);
  cursor: pointer;
  border: none;
  border-radius: calc(4px * 6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(4px * 2) calc(4px * 3);
  width: inherit;
  transition: ease-in background-color 0.1s;
}

.button.button-small {
  padding: 2px 16px;
}

.button.button-center {
  margin: 0 auto;
}

.button.button--link {
  background-color: transparent !important;
  color: hsl(187.9, 60.5%, 46.7%);
}

.button.button--secondary {
  background-color: transparent;
  color: hsl(279.8, 45.1%, 52.2%);
  border: 2px solid hsl(279.8, 45.1%, 52.2%);
  width: calc(4px * 27);
}

.button:disabled {
  cursor: not-allowed;
}

.button.button--primary:disabled,
.button.button-small:disabled {
  background-color: hsla(279.8, 45.1%, 52.2%, 0.75);
}

.button.button--link:disabled {
  background-color: hsla(187.9, 60.5%, 46.7%, 0.75);
}

.button:active:not(:disabled),
.button.button--primary:active:not(:disabled) {
  background-color: hsl(279.8, 45.1%, 57.2%);
}

.button.button--primary {
  width: calc(4px * 27);
}

@media screen and (max-width: 767px) {
  .button .buttonTypography {
    font-size: calc(4px * 4) !important;
  }
}

@media screen and (min-width: 768px) {
  .button {
    padding: calc(4px * 2) calc(4px * 5);
  }

  .button:hover:not(:disabled):not(:active),
  .button.button-small:hover:not(:disabled):not(:active),
  .button.button--primary:hover:not(:disabled):not(:active) {
    background-color: hsl(279.8, 45.1%, 62.2%);
  }

  .button.button--link:hover:not(:disabled):not(:active) {
    background-color: hsl(187.9, 60.5%, 56.7%);
  }

  .button.button--secondary:hover:not(:disabled):not(:active) {
    background-color: hsl(279.8, 45.1%, 62.2%);
    color: #ffffff;
    border: 2px solid hsl(279.8, 45.1%, 62.2%);
  }

  .button.button--primary,
  .button.button.button--secondary {
    width: calc(4px * 40);
  }
}
