.visits-by-injury-type {
  min-height: 352px;
  height: fit-content;
}

.visits-by-injury-type .visits-by-injury-type__title {
  margin-bottom: calc(4px * 6);
}

.visits-by-injury-type .visits-by-injury-type__placeholder {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.visits-by-injury-type .visits-by-injury-type__chart-container {
  position: relative;
}

.visits-by-injury-type__legends {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 309px;
  margin: 0 auto calc(4px * 6) auto;
}

.visits-by-injury-type__legend {
  display: flex;
  align-items: center;
}

.visits-by-injury-type__legend-color {
  border-radius: 2px;
  width: calc(4px * 4);
  height: calc(4px * 4);
  margin-right: calc(4px * 3);
}
