.container {
  background-color: #f2f2f2;
  padding: calc(4px * 6) calc(4px * 4);
}

.container--transparent {
  background-color: transparent;
}

@media screen and (min-width: 768px) {
  .container {
    padding: calc(4px * 6) calc(4px * 7);
  }
}

@media screen and (min-width: 940px) {
  .container {
    padding: calc(4px * 9) calc(4px * 8);
  }
}

@media screen and (min-width: 1024px) {
  .container {
    padding: calc(4px * 9) calc(4px * 30);
    display: flex;
    flex-direction: column;
  }
}
