.typography {
  margin: 0;
}

.typography.displayMedium {
  --font-size: 40px;
  --line-height: 56px;

  font-size: 52px;
  font-weight: bold;
  line-height: 68px;
}

.typography.displaySmall {
  --font-size: 32px;
  --line-height: 44px;

  font-size: 32px;
  font-weight: bold;
  line-height: 44px;
}

.typography.heading1 {
  --font-size: 28px;
  --line-height: 40px;

  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
}

.typography.heading2 {
  --font-size: 24px;

  --line-height: 36px;

  font-size: 24px;
  font-weight: bold;
}

.typography.heading3 {
  --font-size: 22px;
  --line-height: 34px;

  font-size: 22px;
  line-height: 34px;
  font-weight: 500;
}

.typography.subtitle {
  --font-size: 20px;
  --line-height: 32px;

  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
}

.typography.body {
  --font-size: 18px;
  --line-height: 28px;

  font-size: 18px;
  line-height: 28px;
}

.typography.parragraph {
  --font-size: 16px;
  --line-height: 24px;

  font-size: 16px;
  line-height: 24px;
}

.typography.caption {
  --font-size: 14px;
  --line-height: 20px;

  font-size: 14px;
  line-height: 20px;
}
.typography.captionSmall {
  --font-size: 12px;
  --line-height: 16px;

  font-size: 12px;
  line-height: 16px;
}

.typography.link {
  --font-size: 16px;
  --line-height: 24px;

  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 940px) {
  .typography.displayMedium {
    font-size: 58px;
    line-height: 72px;
  }

  .typography.displaySmall {
    font-size: 44px;
    line-height: 56px;
  }

  .typography.heading1 {
    font-size: 36px;
    line-height: 48px;
  }

  .typography.heading2 {
    font-size: 28px;
    line-height: 40px;
  }

  .typography.heading3 {
    font-size: 24px;
    line-height: 36px;
  }
}

.typography.color--primary {
  color: hsl(279.8, 45.1%, 52.2%);
}

.typography.color--error {
  color: #cd5c5c;
}

.typography.color--secondary {
  color: hsl(280, 41.6%, 26.9%);
}

.typography.color--success {
  color: hsl(187.9, 60.5%, 46.7%);
}

.typography.color--alert {
  color: #ff9200;
}

.typography.color--alert {
  color: #cd5c5c;
}

.typography.color--darkness {
  color: #0a1f44;
}

.typography.color--dark {
  color: #53627c;
}

.typography.color--medium {
  color: #8a94a6;
}

.typography.color--light {
  color: #b5bbc6;
}

.typography.color--gray-chateau {
  color: #a0a4a8;
}

.typography.color--abbey {
  color: #52575c;
}

.typography.color--light-dark {
  color: #454f5b;
}

.typography.color--blue-link {
  color: hsl(187.9, 60.5%, 46.7%);
}

.typography.font-weight--normal {
  font-weight: normal;
}

.typography.font-weight--medium {
  font-weight: 500;
}

.typography.font-weight--bold {
  font-weight: bold;
}
