.spinnerContainer {
  align-items: center;
  background-color: transparent;
  justify-content: center;
  display: flex;
  height: 100vh;
  position: relative;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.spinner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(192, 0, 255, 0.2);
  border-right: 1.1em solid rgba(192, 0, 255, 0.2);
  border-bottom: 1.1em solid rgba(192, 0, 255, 0.2);
  border-left: 1.1em solid hsl(279.8, 45.1%, 52.2%);;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-animation: spin 1.1s infinite linear;
  animation: spin 1.1s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
