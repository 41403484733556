.case-distribution {
  min-height: 436px;
  height: fit-content;
}

.case-distribution .case-distribution__title {
  margin-bottom: calc(4px * 6);
}

.case-distribution .case-distribution__placeholder {
  height: 248px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.case-distribution .case-distribution__chart-container {
  position: relative;
  margin-bottom: calc(4px * 3);
}
