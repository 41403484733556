.downgraded-patients__header,
.downgraded-patients__header-alt {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.downgraded-patients__header-alt {
  justify-content: flex-end;
  margin-top: 16px;
}
.downgraded-patients_empty-state-title {
  text-align: center;
}

.downgraded-patients__title {
  flex-grow: 1;
}

.downgraded-patients__header-filters {
  display: flex;
}

.downgraded-patients__view-chart:hover {
  cursor: pointer;
}

.downgraded-patients__table-wrapper {
  max-width: unset;
}

.accordion__header.downgraded-extra-space {
  padding-right: 70px;
}

.downgraded-patients__dropdown-full {
  width: 100%;
}

.downgraded-patients__legends {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 309px;
  margin: 0 auto 28px auto;
}

.downgraded-patients__legend {
  display: flex;
  align-items: center;
}

.downgraded-patients__legend-color {
  border-radius: 2px;
  width: calc(4px * 4);
  height: calc(4px * 4);
  margin-right: calc(4px * 1);
}

.downgraded-patients__chart-footer {
  text-align: center;
}

.downgraded-patients__chart-container {
  margin-bottom: 20px;
}
.downgraded-patients__header-alt + .user-input {
  margin-top: 1em;
}
@media screen and (min-width: 768px) {
  .downgraded-patients__header-filters {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
  }
  .downgraded-patients__header {
    row-gap: 16px;
  }
  .downgraded-patients__dropdown {
    min-width: 240px;
  }
  .downgraded-patients__title {
    margin-bottom: unset;
  }
  .downgraded-patients__legends {
    gap: 0;
    justify-content: space-between;
    max-width: 309px;
  }

  .downgraded-patients__chart-container {
    padding: 0 30px;
    margin-bottom: 40px;
  }
  .downgraded-patients-chart-view {
    margin-bottom: 26px;
  }
  .downgraded-patients__legend-color {
    margin-right: calc(4px * 3);
  }
}
