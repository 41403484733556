.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  touch-action: none;
}

.modal__content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card.modal__main {
  margin-top: 0;
}

.modal__main {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overscroll-behavior: contain;
  max-height: 620px;
  /* height: 100%; */
  overflow-y: auto;
}

.modal__main::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.modal__main::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.modal__level-2 {
  z-index: 2;
}

.modal__level-3 {
  z-index: 3;
}

.modal__size-small {
  width: 90%;
}

.modal__size-medium {
  width: 90%;
}

.modal__size-large {
  width: 90%;
  height: 90%;
}

.modal__size-full {
  width: 100%;
  height: 100%;
  transform: none;
  top: 0;
  left: 0;
}

@media screen and (min-width: 940px) {
  .modal__size-small {
    width: 20%;
  }

  .modal__size-medium {
    width: 50%;
  }
  .modal__size-large {
    width: 80%;
  }

  .modal__size-full {
    width: 100%;
    height: 100%;
    transform: none;
    top: 0;
    left: 0;
  }
  .modal__poc-pdf {
    min-height: 90vh;
    width: 70vw;
  }
}

.modal__display-block {
  display: block;
}

.modal__display-none {
  display: none;
}

.modal__main .modal__main__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #dfe3e8;
  align-items: center;
  padding: calc(2 * 12px);
}

.modal__main .modal__main__header .typography {
  margin-right: 1rem;
}

.modal__close-icon {
  fill: #52575c;
  cursor: pointer;
}

.card.modal__main {
  padding: 0;
}
