.card {
  background-color: #ffffff;
  padding: calc(4px * 8) calc(4px * 7);
  box-shadow: 0px 0px 4px rgba(53, 66, 91, 0.08);
  border-radius: 4px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 93vw;
}

@media screen and (min-width: 400px) {
  .card {
    margin-left: initial;
    margin-right: initial;
    width: initial;
  }
}
