.progress,
.overlay {
  position: fixed;
}

.progress {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 90;
}

.overlay {
  z-index: calc(90 - 1);
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}
