/**
 * THIS CSS IS GLOBAL, BE CAREFUL WHEN EDITING THIS
 */
@font-face {
  font-family: 'CircularStd';
  src: url('assets/CircularStd-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'CircularStd';
  src: url('assets/CircularStd-Black.ttf');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: 'CircularStd';
  src: url('assets/CircularStd-BlackItalic.ttf');
  font-style: italic;
  font-weight: 800;
}
@font-face {
  font-family: 'CircularStd';
  src: url('assets/CircularStd-Bold.ttf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'CircularStd';
  src: url('assets/CircularStd-BoldItalic.ttf');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: 'CircularStd';
  src: url('assets/CircularStd-Book.ttf');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'CircularStd';
  src: url('assets/CircularStd-BookItalic.ttf');
  font-style: italic;
  font-weight: 400;
}
