.recent-patients {
  max-height: 920px;
  width: 100%;
}

.recent-patients > section {
  height: 100%;
}

.recent-patients > .recent-patients__error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.recent-patients .recent-patients__placeholder {
  height: 100%;
}

.recent-patients__header {
  display: flex;
  flex-direction: column;
}

.recent-patients .recent-patients__title {
  margin-bottom: 4px;
  width: fit-content;
}

.recent-patients .recent-patients__error-message {
  margin-bottom: calc(4px * 3);
}

.recent-patients .recent-patients__table {
  display: none;
}

.recent-patients .recent-patients__table th {
  font-weight: 400;
}

.recent-patients .recent-patients__table th:first-child,
.recent-patients .recent-patients__table td:first-child {
  text-align: left;
  padding-left: calc(4px * 7);
}

.recent-patients .recent-patients__table th:last-child {
  padding-right: calc(4px * 7);
}

.recent-patients .recent-patients__table th,
.recent-patients .recent-patients__table td {
  text-align: center;
}

.recent-patients .recent-patients__table td:not(:first-child):not(:last-child) {
  padding-right: 28px;
}

.recent-patients .recent-patients__table td:last-child {
  padding-right: calc(4px * 7 + 28px); /*Extra space from normal td*/
}

.recent-patients .recent-patients__table-header {
  height: 72px;
  position: sticky;
  top: 0;
  background-color: #fff;
}

.recent-patients__table-head {
  --gap: 12px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.recent-patients__table-head > svg {
  color: hsl(279.8, 45.1%, 52.2%);
  margin: 0 0 0 8px;
}

.recent-patients .recent-patients__table-row {
  height: 56px;
}

.recent-patients .recent-patients__table-body {
  max-height: 560px;
  min-width: 900px;
}

.recent-patients__virtual-list {
  min-width: 900px;
}

.recent-patients__table-wrapper {
  overflow: auto;
  position: relative;
  width: 100%;
  max-height: calc(56px * 10 + 72px);
}

.recent-patients .recent-patients__table-header,
.recent-patients .recent-patients__table-row {
  border-bottom: 1px solid #e8e8e8;
}

.recent-patients .recent-patients__table-row:last-child {
  border: none;
}

.recent-patients .recent-patients__table-row--discharged {
  background-color: #f9f9fa;
}

.recent-patients .recent-patients__functional-scale {
  width: calc(4px * 4);
  height: calc(4px * 4);
  margin: 0 auto;
  border-radius: 25px;
}

.recent-patients
  .recent-patients__functional-scale.recent-patients__functional-scale--red {
  background-color: #f03d3d;
}

.recent-patients
  .recent-patients__functional-scale.recent-patients__functional-scale--yellow {
  background-color: #ffcb66;
}

.recent-patients
  .recent-patients__functional-scale.recent-patients__functional-scale--green {
  background-color: #38a169;
}

.recent-patients
  .recent-patients__functional-scale.recent-patients__functional-scale--dark-green {
  background-color: #0a925d;
}

.recent-patients
  .recent-patients__functional-scale.recent-patients__functional-scale--light-green {
  background-color: #67caa4;
}

.recent-patients__functional-scale--tooltip {
  font-size: 14px !important;
  pointer-events: auto !important;
  font-family: CircularStd, Arial, Helvetica, sans-serif;
  padding: 4px 8px !important;
  background-color: #212b36 !important;
  border-radius: 4px !important;
}
.recent-patients__functional-scale--tooltip:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.charts-table-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: flex-start;
  padding: 0.5em 0 1.5em 0;
  border-bottom: 1px solid #dfe3e8;
}
.chart-box:nth-child(odd) {
  border-left: 0.5px solid #dfe3e8;
}

/* .charts-table-container, */
.empty-status__wrapper {
  min-height: 226px;
}

.empty-status__icon-container {
  border-radius: 50%;
  background-color: #f2eaf7;
  width: 64px;
  height: 64px;
  display: grid;
  place-items: center;
  margin-bottom: 8px;
}

.empty-status__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.charts-table__header-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
  flex-grow: 1;
}

.charts-table-header__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 calc(2 * 12px);
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.charts-table-header__container .dropdown {
  min-width: 200px;
}

.charts-table-session-options__container + .dropdown,
.charts-table__header-info + .dropdown {
  width: 100%;
}

.charts-table-download-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 calc(3 * 12px);
}

.charts-table-download-container .charts-table-download-button {
  width: 100%;
  justify-content: center;
}

.chart-table-download__items-selected {
  color: #a0a4a8;
  display: none;
}

.charts-table-download-button {
  border-radius: 4px;
}

.charts-table-option-button {
  padding: calc(4px * 2) calc(4px * 3);
  border-radius: 4px;
  width: auto;
  border: 1px solid hsl(279.8, 45.1%, 57.2%);
  background-color: #fff;
  color: hsl(279.8, 45.1%, 57.2%);
  display: grid;
  margin: 0;
  place-items: center;
}

.charts-table-option-button:disabled {
  border: 1px solid #999;
}

.charts-table-option-button:disabled > .charts-table-option-button__text {
  color: #999;
}

.charts-table-option-button__text {
  font-size: 16px;
  text-align: center;
}

.charts-table-option-button.selected {
  background-color: hsl(279.8, 45.1%, 57.2%);
  color: #fff;
}

.charts-table-session-options__container {
  display: none;
}

.d-none {
  display: none;
}

.chart-box {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-right: 0.5px solid #dfe3e8;
  border-bottom: 0.5px solid #dfe3e8;
  padding: 8px;
}

.chart-box .typography {
  display: flex;
  justify-content: flex-start;
}

.chart-box .chart-box__date {
  font-size: 14px;
  flex-grow: 1;
}

.chart-box .checkmark {
  margin: 0;
}

.chart-box .thumbs-icon {
  margin-left: 0.5rem;
}
/*First two*/
.chart-box:nth-child(1),
.chart-box:nth-child(2) {
  border-top: 0.5px solid #dfe3e8;
}
.chart-box:first-child,
.chart-box:nth-child(2n + 1) {
  border-left: 0.5px solid #dfe3e8;
}

.empty-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.recent-patients_empty-state-title {
  text-align: center;
}

.empty-status__text {
  max-width: 60%;
  text-align: center;
}

.empty-status__close-button {
  border-radius: 4px;
  padding: 6px 60px;
  width: 100%;
}

.empty-status__footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 calc(2 * 12px);
}

.recent-patients__table {
  display: none;
}

.recent-patients__header-info {
  margin-bottom: 12px;
}

.accordion__item-header .patient-name-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}

.accordion__item .patient-name-container .recent-patients__functional-scale {
  margin: 0 10px 0 0;
}

.recent-patients .accordion__item-body-row .recent-patients__functional-scale {
  margin: 0;
}

.recent-patients__accordion .arrow-icon {
  margin-left: 30px;
}

.recent-patients__header-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.recent-patients__header-filters .dropdown {
  width: 100%;
}

.recent-patients__footer {
  display: flex;
  align-items: center;
  margin-top: 1.8rem;
}

.recent-patients__footer .charts-table-option-button {
  width: 100%;
}
.recent-patients__accordion {
  display: block;
}

.recent-patients__accordion .accordion__item-header.selected {
  border-bottom: 1px solid #974ebc;
}

.charts-group__accordion {
  margin: 0 calc(2 * 12px) 20px calc(2 * 12px);
  flex-grow: 1;
  overflow-y: auto;
  max-height: 300px;
}

.charts-group__header {
  display: flex;
  align-items: center;
}
.charts-group__header-icon {
  margin-right: 1em;
}

.charts-group__header .charts-group__icon-open {
  align-self: flex-start;
  margin-top: 0.3em;
}

.charts-group__header .charts-group__title-container {
  display: flex;
  flex-direction: column;
}

.recent-patients__accordion .accordion__item-header {
  padding: 16px 12px;
  justify-content: space-between;
}

.charts-group__accordion .accordion__item-header,
.recent-patients__accordion .accordion__item-header {
  display: flex;
  align-items: center;
}

.recent-patients__accordion .accordion__items {
  max-height: calc(52px * 10);
}

.recent-patients__header-filters .select-container .select-menu {
  padding: 10px 8px;
}
.recent-patients__name-column {
  display: inline-block;
}
.recent-patients__name-column,
.recent-patients__escalated-icon {
  vertical-align: middle;
}
.recent-patients__escalated-icon {
  margin-left: 10px;
}

.patient-treated__tooltip {
  /* font-size: 12px !important;
  pointer-events: auto !important;
  font-family: CircularStd, Arial, Helvetica, sans-serif;
  padding: 12px !important;
  color: #454f5b !important;
  background: #dfe3e8 !important;
  border-radius: 4px !important;
  max-width: 80vw;
  text-align: center; */
  font-size: 14px !important;
  pointer-events: auto !important;
  font-family: CircularStd, Arial, Helvetica, sans-serif;
  padding: 12px !important;
  background-color: #212b36 !important;
  border-radius: 4px !important;
  text-align: center;
  max-width: 80vw;
}

.patient-treated__tooltip:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.recent-patients__table-header-patient.header-with-filter {
  display: flex;
  align-items: center;
}

.recent-patients__table-header-patient .user-input {
  margin: 0 1em;
}

.recent-patients__accordion.empty-results-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .chart-box:nth-child(3) {
    border-top: 0.5px solid #dfe3e8;
  }
  .charts-group__accordion {
    margin: 0 calc(3 * 12px) 20px calc(3 * 12px);
  }
  .recent-patients__accordion {
    display: none;
  }
  .chart-box:nth-child(2n + 1) {
    border-left: none;
  }
  .chart-box:nth-child(3n + 1) {
    border-left: 0.5px solid #dfe3e8;
  }
  .recent-patients .recent-patients__table {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }
  .recent-patients__header {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  .recent-patients__header-info {
    margin-bottom: 0;
  }

  .recent-patients__header .dropdown {
    min-width: 240px;
  }

  .chart-box {
    padding: 12px 15px;
  }
  .chart-box .typography {
    justify-content: flex-start;
  }
  .charts-table-header__container {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 calc(3 * 12px);
    align-items: center;
  }

  .chart-box .chart-box__date {
    font-size: 16px;
  }

  .chart-box .checkmark {
    margin-left: 1rem;
  }

  .charts-table-session-options__container + .dropdown {
    display: none;
    width: auto;
  }
  .charts-table__header-info + .dropdown {
    width: auto;
  }
  .charts-table-download-container {
    justify-content: space-between;
  }

  .charts-table-download-container .charts-table-download-button {
    width: auto;
    justify-content: flex-end;
  }

  .charts-table-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .charts-table-session-options__container {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .charts-table-option-button {
    margin-left: 1rem;
  }
  .charts-table__header-info {
    margin-bottom: 0;
  }

  .chart-table-download__items-selected {
    display: inline;
  }
  .empty-status__close-button {
    width: auto;
  }
  .empty-status__text {
    max-width: 100%;
  }
  .recent-patients__header-filters .dropdown {
    width: auto;
  }
  .recent-patients__header-filters {
    flex-direction: row;
    justify-content: flex-end;
  }
  .recent-patients__footer {
    justify-content: flex-end;
  }
  .recent-patients__footer .charts-table-option-button {
    width: auto;
  }
  .charts-table-option-button:not([disabled]):hover {
    background-color: hsl(279.8, 45.1%, 57.2%);
    color: #fff;
    cursor: pointer;
  }
  .recent-patients__header-filters .dropdown + .dropdown {
    margin-left: 1rem;
  }
  .patient-treated__tooltip {
    max-width: 300px;
  }
}

@media screen and (min-width: 1560px) {
  .chart-box {
    padding: 12px 30px;
  }
}
